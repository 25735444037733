<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Alejandro Zapata Velásquez                              ###### -->
<!-- ###### @date: Abril 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML  :                                                     ###### -->
<!-- ###### la plantilla contiene los siguiente elementos que iteractuan entre si  ###### -->
<!-- ######                                                                        ###### -->                                                    ###### -->
<!-- ######       <v-row>                                                          ###### -->
<!-- ######          | <v-col>  (columna 1)              | <v-col>  (columna 2)    ###### -->
<!-- ######          |   header                          |                         ###### -->
<!-- ######          |      input buscar x código        |    visor de PDFs        ###### -->
<!-- ######          |      input buscar por nombre      |                         ###### -->
<!-- ######          |   tabla                           |                         ###### -->
<!-- ######          |   botón cargar carta agotado      |                         ###### -->
<!-- ######          |   dialog cargar agotado           |                         ###### -->
<!-- ######          |   notificacion diaria activar     |                         ###### -->
<!-- ######                                                                        ###### -->
<!-- #################################################################################### -->
<template>

<v-container fluid v-show="mostrarContenido1" style="height: 81vh; margin-top: 20px">
                    <v-layout> 
                        <v-container fluid style="height: 70vh;">
                            <v-card class="pa-4">             
                                <div>
                                    <h3>Cargar la malla en formato .csv relacionándola con el periodo correspondiente.</h3>
                                </div>
                                    <v-row style="padding: 30px">

                                        <v-col cols="12" md="6">
                                            <label for="ipsOrigenMalla">Selecciona la IPS:</label>
                                                <select id="ipsOrigenMalla" v-model="selectedIps" style="background: aliceblue; padding: 7px; border: 1px solid gray; border-radius: 10px; margin-left: 12px;">
                                                    <option v-for="(ips, index) in ipss" :key="index" :value="ips">
                                                        {{ ips }}
                                                    </option>
                                                </select>
                                                <p>IPS seleccionada: {{ selectedIps }}</p>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <div>
                                                <label for="period">Selecciona el periodo a cargar:</label>
                                                <select id="period" v-model="selectedMonth" :disabled="!allowMonthSelection" @change="checkPeriodValidity" style="background: aliceblue; padding: 7px; border: 1px solid gray; border-radius: 10px; margin-left: 12px;">
                                                <option v-for="(month, index) in months" :key="index" :value="month">
                                                    {{ month }}
                                                </option>
                                                </select>
                                                <p v-if="allowMonthSelection">PERIODO seleccionado: {{ selectedMonth }}</p>
                                                <p v-else>No puede ingresar este mes.</p>
                                            </div>
                                        </v-col>


                                        <v-col cols="12" md="6" style="margin-top: 13px">
                                            <input type="file" ref="mallaRef" class="input-file" accept=".csv" style="border: 1px solid gray;">
                                        </v-col>

                                    </v-row>
                                    <v-row class="justify-end" style="margin-right: 31px; margin-bottom: 10px;">     
                                        <v-btn color="primary" @click="cargarMalla">Cargar Documento</v-btn>
                                    </v-row>
                                    <!-- Mostrar mensaje de éxito -->
                                <v-snackbar v-model="showSuccessMessage" color="success">
                                    Archivo validado. Como resultado se descarga archivo logFile con 
                                    los campos que requiere revisar según norma INSTRUCTIVO 
                                    PARA EL REPORTE DE INFORMACIÓN SEGÚN RESOLUCIÓN 1393/2015 MEDICION 
                                    30 DE JUNIO 2023 CAC-IEP1-I01.
                                    Si el archivo proviene vacio significa que los registros han sido
                                    cargados exitosamente para el periodo indicado.
                                </v-snackbar>

                            </v-card>
                        </v-container>
                    </v-layout>
                </v-container>




</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
  import "jquery-ui/ui/widgets/resizable";
  import $ from "jquery";
  import pdf from 'vue-pdf'
  //import { base64Data } from './base64data.js';
  import { Role } from "../../../../router/role.js";

  export default{
    components: {
    },
    data() {
      return {
        name: 'Documents',
        dialog: false,  // abre modal en cargar carta de agotado por primera vez
        dialog2: false, // abre modal para cargar carta de agotado de nuevo
        tableHeaders: [
            { text: "Código Stone",  value: "nombre",            align: "left",  width: "10%", sortable: false },
            { text: "Nombre",        value: "laboratorio",       align: "left",  width: "60%", sortable: false },
            { text: "Estado",        value: "estado",            align: "left",  width: "10%", sortable: false },
            { text: "Historial",     value: "Historial",         align: "left",  width: "10%", sortable: false },
            { text: "Carta agotado", value: "Carta de agotado",  align: "left",  width: "10%", sortable: false },
        ],
        items: [],
        searchText: '',
        footerProps:{
            'items-per-page-options': [7,15,20],
            'items-per-page-text': 'Items por página:',
            'show-current-page': true,
            'show-first-last-page':true,
        },
        itemsPerPage: 7,
        page: 1,
        totalPage: 2,
        codigoProducto: '',
        fechaReactivacion: '',
        cartaAgotadoFile: null,
        showSuccessMessage: false,
        showSuccessMessage2: false,
        codigoProductoBuscar : '',
        selectedPdf: '',
        pdfView: false,
        src: "",
        numPages: undefined,
        // para trazabilidad de un item
        itemSeleccionado: {},
        dialogoTrazabilidad: false,
        tablaTrazabilidad: {
            items: [],
            headers: [
                { text: "CAMPO", align: "left", width: "20%", sortable: false },
                { text: "VALOR ANTERIOR", align: "left", width: "20%", sortable: false },
                { text: "VALOR ACTUAL", align: "left", width: "20%", sortable: false },
                { text: "FECHA DE MODIFICACIÓN", align: "center", width: "25%", sortable: false },
                { text: "USUARIO", align: "center", width: "15%", sortable: false },
            ],
            footerProps: {
                'show-current-page': true,
                'show-first-last-page': true,
            },
            itemsPerPage: 5,
            page: 1,
        },
        nombresCampos: {
            nombre: 'Nombre',
            codigo: 'Código STONE',
            estado: 'Estado',
            fechareactivacion: 'Fecha reactivación'
        },
        dots : '', // la referencia a este elemento data dots en un método o en el html es this.dots
        showDots : false,
        // Opciones para visualizar Submenú
        verMenu: true,
        roles: Role,
        userRoles: {},
  
        months: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        selectedMonth: null,
        ipss: [
            'MEDICI', 'SALUD PLENA'
        ],
        selectedIps: null,
        allowMonthSelection: true,
        mostrarContenido1: true, // Inicialmente visible
        mostrarContenido: false, // Inicialmente oculto
      };
    },
    computed: {
        // variable computada para filtrar los elementos de la tabla según la busqueda searchText: ''
        filteredItems() {
            if (!this.searchText) {
                return this.items;
            }

            // Verificar si this.items es un arreglo antes de usar filter
            if (!Array.isArray(this.items)) {
                console.error('this.items no es un arreglo:', this.items);
                return [];
            }

            const searchText = this.searchText.toLowerCase();
            return this.items.filter(item => item.nombre.toLowerCase().includes(searchText));
        },
    },

    methods: {
      initialize() {
        this.tableData = [
          { id: 1, medicamento: 'Paracetamol', laboratorio: 'Lab. A', estado: 'Activo', historial: 'Historial A'},
          { id: 2, medicamento: 'Ibuprofeno', laboratorio: 'Lab. B', estado: 'Activo', historial: 'Historial B'},
          { id: 3, medicamento: 'Amoxicilina', laboratorio: 'Lab. C', estado: 'Inactivo', historial: 'Historial C'},
          { id: 4, medicamento: 'Aspirina', laboratorio: 'Lab. D', estado: 'Activo', historial: 'Historial D' },
        ]
      },
      // método para obtener la lista de prouctos desde POSTGRES con cartas de agotado
      getListaDesdeController() {
        this.$http
            .get("msa-tools/ListarCartasAgotados")
            .then((result) => {
            console.log(result)
            this.items =  result.data; 
            console.log(this.items);
            })
            .catch((error) => {
            console.log(error.toJSON());
            });
       },
       // método para abrir en el visor de pdfs desde AWS la carta de agotado del item 
       obtenerCartaAgotado(doc) {
            alert(`Descripción de ${doc}`);
            this.overlay = true
            $('.pdfClose').hide();
            const formData = new FormData();
            formData.append('fileName', doc);
            this.$http
            .post("msa-tools/getFile", formData)
            .then((result) => {
                this.pdfView = true;
                var loadingTask = pdf.createLoadingTask("data:application/pdf;base64," + result.data);
                this.src = loadingTask;
                this.src.promise.then(pdf => {
                    this.numPages = pdf.numPages;
                });
                this.overlay = false
            });
        },
        // Lógica para manejar el clic en el icono verde "note_add" en el estado "Activo"
        // pasa al modal el id y el código del producto
        handleNoteAddClick(item) {
            this.dialog2 = true; // abre modal para cargar carta de agotado de nuevo
            this.codigoProductoBuscar = item.codigo;
            this.searchText = item.nombre;
            console.log(`Clic en el icono "note_add" para el item con ID: ${item.id}`);
        },
        // lógica para abrir el modal en caso de que el producto no esté en la lista
        // no pasa id ni código porque no existe
        handleUpload(){
            this.dialog = true;
        },
        // método para validar por código la existencia del producto en el api de STONE
        consultarProductoPorCodigo(codigoProductoBuscar) {
            // Verificar si el campo del código está vacío
            if (!codigoProductoBuscar) {
                // Limpiar el campo del nombre
                this.searchText = '';
                return; // Salir del método si el código está vacío
            } else {
                // Crear un objeto FormData para empaquetar los datos del formulario
                // Headers Postman: Content-Type: multipart/form-data; boundary=<calculated when request is sent>
                //                  Accept: application/json
                // Body: form-data -> Key : codigoProducto , Value: 33
                // Authorization: Bearer Token : obtenido por consola del front
                const formData = new FormData();
                formData.append('codigoProducto', this.codigoProductoBuscar);
                this.$http 
                .post("msa-tools/getNameByCode", formData, { headers: { 'Accept': 'application/json' }} )
                .then((result) => {
                    if (result.data) {
                        // Asignar el nombre del producto al campo searchText
                        console.log(result.data)
                        this.searchText = result.data;
                    } else {
                        // Si el producto no existe, limpiar el campo searchText
                        setTimeout(() => {
                            this.searchText = '';
                        }, 100);
                    }
                })
                .catch((error) => {
                    console.log(error.toJSON());
                });
            }           
        },
        // Método para cargar: código, nombre, fecha de activación a POSTGRES y documento PDF a AWS
        // cartaAgotadoRef es una auto referencia en el input del doc i.e. crea en las $refs por defecto de vue el campo para alojar el doc
        cargarCartaAgotado() {
            // Crear un objeto FormData para empaquetar los datos del formulario
            const formData = new FormData();
            formData.append('codigoProducto', this.codigoProductoBuscar);
            formData.append('nombreProducto', this.searchText);
            formData.append('fechaReactivacion', this.fechaReactivacion);
            const file = this.$refs.cartaAgotadoRef.files[0];
            if (this.$refs.cartaAgotadoRef.files.length > 0) {
                formData.append('cartaAgotadoFile', file);
            }
            console.log(formData.get('cartaAgotadoFile'))
            console.log(formData)
            setTimeout(() => { this.dialog = false; }, 3000);
            this.limpiarFormulario();
            this.$http
                .post("msa-tools/guardarCartaAgotado", formData, { headers: { 'Accept': 'application/json' } })
                .then((result) => {
                    console.log(result)
                    this.showSuccessMessage = true;
                }) 
                .catch((error) => {
                    console.log(error?.response?.data);
                });
        },
        cargarCartaAgotado2() {
            // Crear un objeto FormData para empaquetar los datos del formulario
            const formData = new FormData();
            formData.append('codigoProducto', this.codigoProductoBuscar);
            formData.append('nombreProducto', this.searchText);
            formData.append('fechaReactivacion', this.fechaReactivacion);
            const file = this.$refs.cartaAgotadoRef.files[0];
            if (this.$refs.cartaAgotadoRef.files.length > 0) {
                formData.append('cartaAgotadoFile', file);
            }
            console.log(formData.get('cartaAgotadoFile'))
            console.log(formData)
            setTimeout(() => { this.dialog = false; }, 3000);
            this.limpiarFormulario();
            this.$http
                .post("msa-tools/guardarCartaAgotado2", formData, { headers: { 'Accept': 'application/json' } })
                .then((result) => {
                    console.log(result)
                    this.showSuccessMessage = true;
                }) 
                .catch((error) => {
                    console.log(error?.response?.data);
                });
        },
        limpiarFormulario() {
            this.codigoProducto = '';
            this.fechaReactivacion = '';
            this.cartaAgotadoFile = null;
            // Restablecer el campo de entrada de archivo
            this.$refs.cartaAgotadoRef.value = '';
        },     
        /**
        * Muestra tabla de trazabilidad de un item de tabla determinado mediante una petición get 
        * que obtiene los datos de la trazabilidad a través del parámetro id que es la variable 
        * "itemSeleccionado.id" @param {Object} item - El objeto del item seleccionado
        * (los datos actuales de la entidad) para el cual se mostrará la trazabilidad.
        */
        verTablaTrazabilidad(item) {
            this.dialogoTrazabilidad = true;
            this.itemSeleccionado = item;
            this.$http
            .get(`msa-tools/listarTraza`, {
                params: {
                    id: this.itemSeleccionado.id,
                }
            }).then(response => {
                this.tablaTrazabilidad.items = response.data; console.log(response.data)
            }).catch(error => {
                console.log(error);
            });
        },
          /**
         * Valida si el campo de un objeto debe ser excluido en la tabla de trazabilidad
         * @param {Object} item - El objeto que contiene el campo a validar.
         * @param {string} item.campo - El nombre del campo a validar.
         */
         validarCampos(item) {
            const excluir = ['nombre', 'codigo']
            return excluir.includes(item.campo)
        },
        formatoFecha(dateTimeString) {
            const fecha = new Date(dateTimeString);
            const horas = fecha.getHours();
            const minutos = fecha.getMinutes();
            const ampm = horas >= 12 ? 'p.m.' : 'a.m.';
            const formatoHoras = horas % 12 || 12;
            const formatoMinutos = minutos < 10 ? `0${minutos}` : minutos;
            const formatoMes = (fecha.getMonth() + 1).toString().padStart(2, '0');
            const formatoDia = fecha.getDate().toString().padStart(2, '0');
            const formatoFechaHora = `${fecha.getFullYear()}/${formatoMes}/${formatoDia} ${formatoHoras.toString().padStart(2, '0')}:${formatoMinutos} ${ampm}`;
            return formatoFechaHora;
        },
        cambiarEstado(item) { 
            const formDataUpdate = new FormData(); console.log(item)
            formDataUpdate.append('idProducto', item.id);
            this.$http
                .post("msa-tools/actualizarCartaAgotado", formDataUpdate, { headers: { 'Accept': 'application/json' } })
                .then((result) => {
                    console.log(result)
                    this.showSuccessMessage2 = true;
                    item.estado = 'Activo';
                    this.getListaDesdeController();
                }) 
                .catch((error) => {
                    console.log(error?.response?.data);
                });
            // item.estado = item.estado === "Agotado" ? "Activo" : "Agotado";
        },
        // Agrega una función para mostrar "..." durante tres segundos antes de cambiar a "Activo"
        showDotsForThreeSeconds(item) {
            this.dots = '...';
            this.showDots = true;
            setTimeout(() => {
                this.showDots = false;
                this.cambiarEstado(item);
            }, 3000); // 3000 milisegundos = 3 segundos
        },
        subirCartaDeNuevo(item) { 
            const formDataUpdate = new FormData(); console.log(item)
            formDataUpdate.append('idProducto', item.id);
            this.$http
                .post("msa-tools/actualizarCartaAgotado", formDataUpdate, { headers: { 'Accept': 'application/json' } })
                .then((result) => {
                    console.log(result)
                    this.showSuccessMessage2 = true;
                    item.estado = 'Activo';
                    this.getListaDesdeController();
                }) 
                .catch((error) => {
                    console.log(error?.response?.data);
                });
            // item.estado = item.estado === "Agotado" ? "Activo" : "Agotado";
        },
        // Método para cargar: código, nombre, fecha de activación a POSTGRES y documento PDF a AWS
        // cartaAgotadoRef es una auto referencia en el input del doc i.e. crea en las $refs por defecto de vue el campo para alojar el doc
        cargarMalla() {
            // Crear un objeto FormData para empaquetar los datos del formulario
            const formData = new FormData();
            formData.append('ips_origen_malla', this.selectedIps);
            formData.append('period', this.selectedMonth);
            const file = this.$refs.mallaRef.files[0];
            if (this.$refs.mallaRef.files.length > 0) {
                formData.append('mallaFile', file);
            }
            console.log(formData)
            setTimeout(() => { this.dialog = false; }, 3000);
            //this.limpiarFormularioMalla();
            this.$http
                .post("msa-localapp/api/cargar_malla", formData, { headers: { 'Accept': 'application/json' } })
                .then((response) => { 
                    const blob = new Blob([response.data], { type: 'text/plain' });
                    const url = window.URL.createObjectURL(blob);
                
                    // Crea un enlace para descargar el archivo
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'logFile.txt');
                    document.body.appendChild(link);
                    link.click();

                    // Limpia la URL creada para el Blob
                    window.URL.revokeObjectURL(url);
                    this.showSuccessMessage = true;
                }) 
                .catch((error) => {
                    console.log(error?.response?.data);
                });
        },
        async checkPeriodValidity() {
            try {
                const formData = new FormData();
                formData.append('ips_origen_malla', this.selectedIps);
                formData.append('period', this.selectedMonth);
                this.$http
                .post("msa-localapp/api/validar_periodo", formData, { headers: { 'Accept': 'application/json' } })
                .then((response) => { 
                    if (response.data && response.data > 0) {
                        // Bloquear la selección del mes
                        this.allowMonthSelection = false;
                        alert('Ya has subido registros para este mes. Haz clic en OK para seleccionar otro mes.');
                        this.allowMonthSelection = true; // Desbloquear la selección del mes
                        this.selectedMonth = ''; // Reiniciar la selección del mes
                    } else {
                        // Permitir la selección del mes si no hay registros
                        this.allowMonthSelection = true;
                    }
                }) 
                .catch((error) => {
                    console.log(error?.response?.data);
                });
            } catch (error) {
                console.error('Error al llamar al servicio API:', error);
            }
        },

        mostrarPrimerContenido() {
            this.mostrarContenido1 = true; // Asegúrate de mostrar el primer contenido al hacer clic
            this.mostrarContenido = false;
        },
        cargarContenido() {
            // Cambiar el estado de 'foco' al hacer clic en el tab
            this.mostrarContenido1 = false; // Asegúrate de mostrar el primer contenido al hacer clic
            this.mostrarContenido = true;
        },
        

    }, // Fín methods

    mounted() {
        this.getListaDesdeController()
       // this.getListaDesdeServicio();
       // this.getListaDesdeRepositorio();
    },

    created() {
        this.initialize()
    }

  };
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
    .search-input {
        width: 100%;
        border-radius: 20px;
        border: 1px solid #ccc;
        padding: 10px;
        box-sizing: border-box;
    }
    ::v-deep .elevation div table thead tr th {
        background-color: rgb(223, 223, 223) !important;
    }

    ::v-deep .elevation div table thead tr th span {
        font-weight: bold;
        color: black !important;
    }
    .center-icon {
        text-align: center; /* Centrar horizontalmente */
        vertical-align: middle; /* Centrar verticalmente */
    }
    .blueGlobal {
    background-color: blue;
    /* Estilos adicionales para el encabezado */
  }

  /* estilos para el header que contiene input de busqueda y boton de cargar carta de agotado */
  .search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: white;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.upload-message {
  margin-bottom: 10px;
}

.upload-btn {
  width: 300px;
background-color: green;

}

.divCard{
    margin-top: 5px;
}

.container {
  margin-top: -20px; /* Ajusta el valor según tus necesidades */
  overflow-x: hidden;
}

.boxPdf {
  overflow: auto;
  height: 100vh;
}

.boxPdf>span {
  width: 100%;
}

.input-file{
    padding: 21px;
    margin: 20p;
    background: aliceblue;
    margin-top: -22px;
    width: 100%;
}

.estado-button {
    cursor: pointer;
    background-color: #caced3;
    color: gray;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: normal;
    font-size: 11px;
}

.estado-button:hover {
  background-color: green;
  color: white
}

#period{
    width: 113px;
    padding: 5px;
    border: 1px solid #d6d5e0;
    border-radius: 7px;
    margin-left: 10px;
}
#ips_origen_malla{
    width: 113px;
    padding: 5px;
    border: 1px solid #d6d5e0;
    border-radius: 7px;
    margin-left: 10px;
}

</style>
